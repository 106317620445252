import Error from "@/core/services/Error";

const DOCUMENT_STATE = {
  id: null,
  title: null,
  file: null,
  previewFile: null,
  video_link: null,
};

export default {
  data() {
    return {
      brochure_document: { ...DOCUMENT_STATE },
      brochure_documents: []
    };
  },
  methods: {
    async handleAddUpdateDocumentClick() {
      this.formErrors = new Error({});
      let errors = {};

      if ( this.brochure_documents.length > 5 ) {
        this.brochure_document.error = true;
        errors.name = ['Maximum 5 Document you can add here.'];
        this.formErrors = new Error(errors);
        return false;
      }


      this.brochure_document.error = false;

      if ( !this.brochure_document.name ) {
        this.brochure_document.error = true;
        errors.name = ['The field title is required.'];
      }

      if ( !this.brochure_document.file && !this.brochure_document.video_link ) {
        this.brochure_document.error = true;
        errors.file = ['The field file is required.'];
      }

      if ( !this.brochure_document.video_link && !this.brochure_document.file ) {
        this.brochure_document.error = true;
        errors.video_link = ['The field link is required.'];
      }

      _.map(this.brochure_documents, (feature) => {
        if ( feature.token !== this.brochure_document.token ) {
          if ( ( feature.name === this.brochure_document.name ) ) {
            errors.name = ['Duplicate entry'];
            this.brochure_document.error = true;
            this.formErrors = new Error(errors);
            return false;
          }
        }
      });

      this.formErrors = new Error(errors);
      if ( this.brochure_document.error ) {
        return false;
      }

      if ( this.brochure_document.file ) {
        this.brochure_document.file = this.fFile;
        this.brochure_document.original_file_name = this.fFile.name;
        this.brochure_document.previewFile = URL.createObjectURL(this.fFile);
      }

      const entity = this.brochure_document;
      let index = -1;
      if ( entity.token ) {
        index = _.findIndex(this.brochure_documents, { token: entity.token });
      }

      if ( this.brochure_documents[index] !== undefined ) {
        this.brochure_documents[index] = entity;
      } else {
        entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.brochure_documents.push(entity);
      }

      this.brochure_document = {
        ...DOCUMENT_STATE,
        token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      };

    },
    async handleDeleteDocumentClick(token) {
      const index = _.findIndex(this.brochure_documents, { token: token });
      if ( this.brochure_documents[index] !== undefined ) {
        this.brochure_documents.splice(index, 1);
      }
    },
    handleEditDocumentClick(token) {
      const index = _.findIndex(this.brochure_documents, { token: token });

      if ( this.brochure_documents[index] !== undefined ) {
        this.brochure_documents[index] = {
          ...this.brochure_documents[index],
        };

        this.brochure_document = { ...this.brochure_documents[index] };
      }
    },
    resetDocument() {
      this.brochure_document = { ...DOCUMENT_STATE };
    },
  }
};
