<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{ formFields.id ? `Edit ${ operationTitle }` : `Add ${ operationTitle }` }}
                    </template>
                    <template v-slot:toolbar>
                        <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                                  size="sm"
                                  variant="primary"
                                  :disabled="global.pendingRequests > 0"
                        >
                            <i v-show="global.pendingRequests > 0"
                               class="fa fa-spin fa-spinner"></i>
                            <i class="fa fa-save fa-sm"></i>
                            Save
                        </b-button>
                        <b-button variant="danger" size="sm" class="mt-3"
                                  @click="handleOperationClose()">
                            <i class="fa fa-arrow-left fa-sm"></i> Cancel
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="brochures-operation">
                                <b-container fluid>
                                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                                        <b-row>
                                            <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                                                <b-row>
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('title')"
                                                            label="Name Of Brochure *"
                                                            label-for="title"
                                                        >
                                                            <b-form-input
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                                id="title"
                                                                type="text"
                                                                v-model="formFields.title"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="12" md="12" sm="12">
                                                        <b-card class="mb-0">
                                                            <b-card-header v-b-toggle.hall-slots class="p-0">
                                                                <h4 class="mb-0">
                                                            <span class="badge badge-primary">
                                                                Add Brochure Documents
                                                            </span>
                                                                </h4>
                                                            </b-card-header><!-- /.p-0-->
                                                            <b-collapse
                                                                id="hall-slots">
                                                                <div class="bg-light p-3">
                                                                    <b-row>
                                                                        <b-col lg="2" md="2" sm="12">
                                                                            <b-form-group
                                                                                label="Title *"
                                                                                label-for="title"
                                                                                :invalid-feedback="formErrors.first('name')"
                                                                            >
                                                                                <b-form-select
                                                                                    :options="['Video Link', 'Photo Gallery', 'Packages Menu', 'Food Menu']"
                                                                                    :state="((formErrors.has('name') ? false : null))"
                                                                                    v-model="brochure_document.name"
                                                                                    placeholder="title">
                                                                                </b-form-select>
                                                                            </b-form-group>
                                                                        </b-col><!--/b-col-->
                                                                        <b-col lg="3" md="3" sm="12">
                                                                            <b-form-group
                                                                                v-if="brochure_document.name !== 'Video Link'"
                                                                                label="File"
                                                                                label-for="file"
                                                                                :invalid-feedback="formErrors.first('file')"
                                                                            >
                                                                                <b-form-file
                                                                                    ref="file"
                                                                                    :state="((formErrors.has('file') ? false : null))"
                                                                                    @input="onChangeFile"
                                                                                    accept="application/pdf,application/vnd.ms-excel,image/*,audio/*,video/*"
                                                                                    id="brochure"
                                                                                    v-model="brochure_document.file">
                                                                                </b-form-file>
                                                                            </b-form-group>
                                                                            <b-form-group
                                                                                v-else
                                                                                label="File"
                                                                                label-for="file"
                                                                                :invalid-feedback="formErrors.first('video_link')"
                                                                            >
                                                                                <b-form-input
                                                                                    type="url"
                                                                                    :state="((formErrors.has('video_link') ? false : null))"
                                                                                    id="brochure"
                                                                                    v-model="brochure_document.video_link">
                                                                                </b-form-input>
                                                                            </b-form-group>
                                                                            <span v-if="formErrors.has('link')"
                                                                                  class="text-danger">
                                                                                    {{ formErrors.first('link') }}
                                                                                </span>
                                                                        </b-col><!--/b-col-->
                                                                        <b-col lg="2" md="2" sm="12">
                                                                            <div class="form-group">
                                                                                <label class="d-block">
                                                                                    <pre> </pre>
                                                                                </label>
                                                                                <b-button variant="primary"
                                                                                          type="button"
                                                                                          size="sm"
                                                                                          class="btn-block"
                                                                                          @click="handleAddUpdateDocumentClick()">
                                                                                    Add Document
                                                                                </b-button><!--/b-button-->
                                                                            </div><!-- /.form-group -->
                                                                        </b-col><!--/b-col-->
                                                                        <b-col lg="1" md="2" sm="12">
                                                                            <div class="form-group">
                                                                                <label class="d-block">
                                                                                    <pre> </pre>
                                                                                </label>
                                                                                <b-button variant="warning"
                                                                                          type="button"
                                                                                          size="sm"
                                                                                          class="btn-block"
                                                                                          @click="resetDocument()">
                                                                                    Reset
                                                                                </b-button><!--/b-button-->
                                                                            </div><!-- /.form-group -->
                                                                        </b-col><!--/b-col-->
                                                                    </b-row><!--/b-row-->
                                                                    <b-row>
                                                                        <b-col cols="12">
                                                                            <table
                                                                                class="table table-bordered bg-white">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th width="50">#</th>
                                                                                        <th width="80">Title</th>
                                                                                        <th width="180">Document</th>
                                                                                        <th width="60">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(cl, index) in brochure_documents"
                                                                                        :key="index"
                                                                                        :class="[{'table-primary': cl.token === brochure_document.token}]">
                                                                                        <td>{{ index + 1 }}</td>
                                                                                        <td class="80">{{ cl.name }}
                                                                                        </td>
                                                                                        <td>
                                                                                            <span
                                                                                                v-if="cl.type == 'link' || cl.name == 'Video Link'">
                                                                                                {{ cl.video_link }}
                                                                                            </span>
                                                                                            <span v-else>
                                                                                                <a :href="cl.previewFile"
                                                                                                   target="_blank">
                                                                                                    <i class="fa fa-download"></i> {{cl.original_file_name}}
                                                                                                </a>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <a @click="handleEditDocumentClick(cl.token)"
                                                                                               title="Edit Item"
                                                                                               v-b-tooltip.hover>
                                                                                                <span
                                                                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                                                                  <!--begin::Svg Icon-->
                                                                                                  <inline-svg
                                                                                                      src="/media/svg/icons/Communication/Write.svg"
                                                                                                      class="action-edit-button"
                                                                                                  ></inline-svg>
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                title="Are you sure？"
                                                                                                @click="handleDeleteDocumentClick(cl.token)">
                                                                                                <i slot="icon"
                                                                                                   class="fe fe-trash"></i>
                                                                                                <a class="ml-1"
                                                                                                   title="Delete Item"
                                                                                                   v-b-tooltip.hover>
                                                                                                    <img
                                                                                                        alt="delete icon"
                                                                                                        src="../../../../public/media/icons/actionicon3.png"
                                                                                                        class="action-delete-button" />
                                                                                                </a>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                                <tfoot
                                                                                    v-show="brochure_documents.length <= 0">
                                                                                    <tr>
                                                                                        <th colspan="5">No data
                                                                                            available
                                                                                        </th>
                                                                                    </tr>
                                                                                </tfoot>
                                                                            </table><!-- /.table table-bordered -->
                                                                        </b-col><!--/b-col-->
                                                                    </b-row><!--/b-row-->
                                                                </div>
                                                            </b-collapse>
                                                        </b-card>
                                                    </b-col>
                                                </b-row>
                                                <hr />
                                                <b-row class="operation-footer">
                                                    <b-col sm="12">
                                                        <b-button
                                                            :disabled="global.pendingRequests > 0"
                                                            size="sm"
                                                            type="submit"
                                                            variant="primary"
                                                        >
                                                            <i class="fa fa-spin fa-spinner"
                                                               v-show="global.pendingRequests > 0"></i>
                                                            <i class="fa fa-save fa-sm"></i>
                                                            Save
                                                        </b-button>
                                                        <b-button @click="handleOperationClose()" class="ml-3"
                                                                  size="sm"
                                                                  variant="danger">
                                                            <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </form><!--/form-->
                                </b-container><!--/b-container-->
                            </div><!--/.brochures-operation-->
                        </v-card>
                    </template>
                </KTCard>
            </div>
        </div>
    </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import moduleMixin from "./module.mixin";
import { mapGetters, mapState } from "vuex";
import documentMixin from "./documentMixin";

export default {
    mixins: [moduleMixin, documentMixin],
    components: {
        KTCard,
    },
    mounted() {
        this.brochure_documents.length = 0;

        if ( this.$route.params && this.$route.params.id && this.$route.params.type ) {
            this.handleEditOperation(this.$route.params.id);
        }
    },
    computed: {
        ...mapState([
            'global',
        ]),
        ...mapGetters({ getActiveProperty: 'getActiveProperty' }),
    },
};
</script>
